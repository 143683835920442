/**
 * LM-LIFF-11 資格判斷
 * https://lmliff.cht.com.tw/unqualified
 */
import { useCallback, useEffect, useMemo } from 'react';

import imgUnqualified from '@/assets/images/img-unqualified.svg';
import StandardButton from '@/components/StandardButton';
import useLoading from '@/hooks/useLoading';
import ModuleFlowResult from '@/modules/ModuleFlowResult';
import SVG from '@/patterns/SVG';
import { PropTypes } from '@/utils/propTypes';
import { styled } from '@/utils/styledComponents';

import { REDIRECT_TO_URL } from '../../constants/urls';

const StyledImage = styled(SVG)`
  margin-top: 82px;
  margin-bottom: 52px;
`;

const renderImage = () => {
  return <StyledImage src={imgUnqualified} />;
};

const UnqualifiedPage = (
  /** @type {{location: {state: { title: string, message: string, target_url:string}}}} */ {
    location,
  }
) => {
  const {
    title,
    message,
    target_url: redirectUrl,
  } = useMemo(
    () =>
      (typeof window !== 'undefined' && location.state) ?? {
        title: '發生異常',
        message: '請稍後再試',
        target_url: REDIRECT_TO_URL.LINE_OA_FOLLOW,
      },
    [location]
  );
  const { isLoading, showLoading, hideLoading } = useLoading();

  useEffect(() => {
    if (isLoading && !!title) hideLoading();
  }, [title, isLoading, hideLoading, showLoading]);

  /**
   * onConfirmToGo 確認按鈕
   * 轉導頁面
   */
  const onConfirmToGo = useCallback(() => {
    window.location.replace(redirectUrl);
  }, [redirectUrl]);

  const renderButtons = () => (
    <>
      <StandardButton type="primary" onClick={onConfirmToGo}>
        確認
      </StandardButton>
    </>
  );
  return (
    <ModuleFlowResult
      title={title}
      message={message}
      renderImage={renderImage}
      renderButtons={renderButtons}
    />
  );
};
UnqualifiedPage.propTypes = {
  location: PropTypes.object,
};
export default UnqualifiedPage;
